const USER_ROLES = {
  ADMIN: 'admin',
};

const LANGUAGES = [
  "fr",
  "en",
  "de",
  "it",
  "es"
];

const FIRST_LOCATION = 1;

const BLOG_POSTS = [
  {
    id: 1,
    next_post_id: 2,
    prev_post_id: 6,
    image_url: require('@/assets/img/front/actualites.webp'),
    date: "bvz1eh8r",
    date_article: "bvz1eh8r",
    category: "iiwjpc5l",
    title: "gcrfu6bw",
    short_description: "u0eleliy",
    description: ""
},
{
    id: 2,
    next_post_id: 3,
    prev_post_id: 1,
    image_url: require('@/assets/img/front/sandinavie3.webp'),
    date: "hh1h5qr4",
    date_article: "14a8ls31",
    category: "iiwjpc5l",
    title: "98wjxhgh",
    short_description: "c5d5zsd9",
    description: "r6zrltut"
},
{
    id: 3,
    next_post_id: 5,
    prev_post_id: 3,
    image_url: require('@/assets/img/front/mixingsuite.webp'),
    date: "pjo2a0g6",
    date_article: "l6mtqz6z",
    category: "iiwjpc5l",
    title: "b7mi52pn",
    short_description: "pd1rpfma",
    description: "x0k5vw0i"
},
{
    id: 4,
    next_post_id: 4,
    prev_post_id: 2,
    image_url: require('@/assets/img/front/italynews.webp'),
    date: "p3nt2vxz",
    date_article: "fmtrsyyh",
    category: "iiwjpc5l",
    title: "llgefyhg",
    short_description: "og6db1c5",
    description: "pft1j3eb"
},
{
    id: 5,
    prev_post_id: 4,
    next_post_id: 6,
    image_url: require('@/assets/img/front/adhocspain.webp'),
    date: "ve7fy2g8",
    date_article: "8hjardjj",
    category: "iiwjpc5l",
    title: "akxv9zti",
    short_description: "r231xjxg",
    description: "5fox21j5"
},
{
    id: 6,
    prev_post_id: 5,
    next_post_id: 7,
    image_url: require('@/assets/img/front/dubbing-brothers-italy-b-100.webp'),
    date: "43qg2yyl",
    date_article: "43qg2yyl",
    category: "iiwjpc5l",
    title: "kfs6xslh",
    short_description: "r30g7xdg",
    description: "2kz7lhwz"
},
{
  id: 7,
  prev_post_id: 6,
  next_post_id: 8,
  image_url: require('@/assets/img/front/Dubbing_Brothers_PR_Image_1.webp'),
  date: "a91mw9yj", 
  date_article: "hgtcu0v5",
  category: "iiwjpc5l",
  title: "yjc8nuyz",
  short_description: "tesva0p6",
  description: "gdr1lqkh"
},
{
  id: 8,
  prev_post_id: 7,
  next_post_id: 1,
  image_url: require('@/assets/img/front/Jerome-Dubbing-Brothers-Main-Blog.webp'),
  date: "8mmghsgz",
  date_article: "loockxyx",
  category: "iiwjpc5l",
  title: "knjzbkjr",
  short_description: "2p82k4ok",
  description: "pjh90sok"
}
]

const CONTACT_LOCATIONS_DATA = [
  {
    id: 1,
    name: "France",
    title: 'DUBBING BROTHERS FRANCE',
    address_line_1: '19 rue de la Montjoie',
    address_line_2: '93210 La Plaine Saint-Denis',
    address_line_3: 'France',
    phone: '0033 1 41 62 74 00',
    phone_display: '+33 1 41 62 74 00',
    contact: "/#/contact/1",
    website: null
  },
  {
    id: 5,
    name: "Belgium",
    title: 'DUBBING BROTHERS BELGIUM',
    address_line_1: 'Av de Scheut, 19 Anderlecht',
    address_line_2: '1070 Bruxelles',
    address_line_3: 'Belgium',
    phone: '0032 2 646 33 72',
    phone_display: '+32 (2) 646 33 72',
    contact: "/#/contact/5",
    website: null
  },
  {
    id: 6,
    name: "FFS Germany - Berlin",
    title: 'FFS by DUBBING BROTHERS',
    address_line_1: 'Hohenzollerndamm 150',
    address_line_2: '14199 Berlin',
    address_line_3: 'Germany',
    phone: '0049 30 78 79 17',
    phone_display: '+49 (0) 30 / 78 79 17 - 0',
    contact: "mailto:info.berlin@ffs-synchron.de",
    website: "https://www.ffs-synchron.de"
  },
  {
    id: 2,
    name: "FFS Germany - Munich",
    title: 'FFS by DUBBING BROTHERS',
    address_line_1: 'Poccistraße 3',
    address_line_2: '80336 München',
    address_line_3: 'Germany',
    phone: '0049 89 76 70 84',
    phone_display: '+49 (0) 89 / 76 70 84 - 0',
    contact: "mailto:info@ffs-synchron.de",
    website: "https://www.ffs-synchron.de"
  },
  {
    id: 7,
    name: "Interopa Germany",
    title: 'Interopa',
    address_line_1: 'Harzer Str. 39',
    address_line_2: '12059 Berlin',
    address_line_3: 'Germany',
    phone: '0049 30 6898960',
    phone_display: '+49 30 6898960',
    contact: "mailto:braune@interopa.de",
    website: "https://www.interopa.de"
  },
  {
    id: 4,
    name: "Italy",
    title: 'DUBBING BROTHERS ITALIE',
    address_line_1: 'Via Malcesine 41',
    address_line_2: '00135 Rome',
    address_line_3: 'Italy',
    phone: '0039 06 3555 41',
    phone_display: '+39 (06) 3555 41',
    contact: "mailto:micaela.prisco@dubbing-brothers.com",
    website: null
  },
  {
    id: 11,
    name: "Spain",
    title: 'DUBBING BROTHERS SPAIN',
    address_line_1: 'Avenida Carabanchel Alto, 56',
    address_line_2: '28044 Madrid',
    address_line_3: 'Spain',
    phone: '0034 915 116 640',
    phone_display: '+34 915 116 640',
    contact: "mailto:infospain@dubbing-brothers.com",
    website: "http://adhocstudios.es/es/"
  },
  {
    id: 10,
    name: "Denmark",
    title: 'Eurotroll',
    address_line_1: 'Viborggade 70C,',
    address_line_2: '2100 København,',
    address_line_3: 'Danemark',
    phone: '0046 8 33 44 33',
    phone_display: '+46 8 33 44 33',
    contact: "mailto:oskar@eurotroll.se",
    website: "https://www.eurotroll.se"
  },
  {
    id: 9,
    name: "Finland",
    title: 'Eurotroll',
    address_line_1: 'Kasöörinkatu 5,',
    address_line_2: '00520 Helsinki,',
    address_line_3: 'Finland',
    phone: '0046 8 33 44 33',
    phone_display: '+46 8 33 44 33',
    contact: "mailto:oskar@eurotroll.se",
    website: "https://www.eurotroll.se"
  },
  {
    id: 8,
    name: "Sweden",
    title: 'Eurotroll',
    address_line_1: 'Narvavägen 7',
    address_line_2: '114 60 Stockholm',
    address_line_3: 'Sweden',
    phone: '0046 8 33 44 33',
    phone_display: '+46 8 33 44 33',
    contact: "mailto:oskar@eurotroll.se",
    website: "https://www.eurotroll.se"
  },
  {
    id: 3,
    name: "USA",
    title: 'DUBBING BROTHERS USA',
    address_line_1: '3610 W. Magnolia Blvd,',
    address_line_2: 'CA 91505, Burbank',
    address_line_3: 'USA',
    phone: '001 818 531-0400',
    phone_display: '+1 (818) 531-0400',
    contact: "mailto:info@dubbingbrothersusa.com",
    website: null
  }
];

const FR_BRANCH = 1;

const COOKIE_PREFERENCES_CONSENT_DONE = "cookies_consent";
const COOKIE_PREFERENCES_TRACKING_ACCEPTED = "cookies_tracking";
const COOKIE_PREFERENCES_SESSION_ACCEPTED = "cookies_session"; // to be used to save prefered language, customizable information for the future

export default {
  APP_CODE: 'dubbing-brothers',
  USER_ROLES,
  LANGUAGES,
  CONTACT_LOCATIONS_DATA,
  FIRST_LOCATION,
  BRANCHES: {
    FR_BRANCH: 1,
    DK_BRANCH: 10,
    FI_BRANCH: 9,
    SV_BRANCH: 8,
  },
  SELECTED_BRANCH_ID: FR_BRANCH,
  BLOG_POSTS: BLOG_POSTS,
  COOKIE_PREFERENCES_CONSENT_DONE,
  COOKIE_PREFERENCES_TRACKING_ACCEPTED,
  COOKIE_PREFERENCES_SESSION_ACCEPTED
};
