<template>
    <div id="newsPage" class="singlePage">
        <main class="newsItem row justify-content-center">
            <div class="col-11">
                <div class="row justify-content-center" v-if="post.id">
                    <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8" v-if="post.id === 1">
                        <div class="d-flex flex-column">
                            <div>
                                <h2>{{ $t(post.title) }}</h2>
                                <p>{{ $t(post.date) }}</p>
                                <div class="newsContent ">
                                    <div class="videoNewsWrapper">
                                    <video v-if="langSelected == 'fr'" autoplay controls loop playsinline class="videoNews"><source src="@/assets/img/video_procession_fr.mp4" type="video/mp4" /></video>
                                    <video v-else autoplay loop controls playsinline class="videoNews"><source src="@/assets/img/video_procession_en.mp4" type="video/mp4" /></video>

                                    <video v-if="langSelected == 'fr'" autoplay loop controls playsinline class="videoNews videoResponsiveNews fr"><source src="@/assets/img/news-video-fr-vertical.mp4" type="video/mp4" /></video>
                                    <video v-else autoplay loop controls playsinline class="videoNews videoResponsiveNews en"><source src="@/assets/img/news-video-en-vertical.mp4" type="video/mp4" /></video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8" v-else>
                        <div class="d-flex flex-column justify-content-between">
                            <div>
                                <h2>{{ $t(post.title) }}</h2>
                                <p><span v-if="post.id == 6">{{ $t(post.category) }} -</span> {{ $t(post.date) }}</p>
                                <div class="newsContent">
                                    <div class="imgWrapper" v-if="post.id != 6">
                                        <img :src="post.image_url" alt="studio">
                                    </div>
                                    <div v-if="post.id === 6">
                                        <div id="carouselNews"  class="carousel slide carousel-fade"  data-bs-interval="2000" data-bs-ride="carousel">
                                            <div class="carousel-inner">
                                                <div class="carousel-item active" data-bs-interval="5000">
                                                    <div id='new-image-swipe'>
                                                        <img src="../assets/img/front/italy1.jpg" class="d-block" alt="italian studio of recording">
                                                    </div>
                                                </div>
                                                <div class="carousel-item" data-bs-interval="5000">
                                                    <div id='new-image-swipe'>
                                                        <img src="../assets/img/front/italy2.jpg" class="d-block" alt="italian studio of recording">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="carousel-indicators">
                                                <button type="button" data-bs-target="#carouselNews" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                                <button type="button" data-bs-target="#carouselNews" data-bs-slide-to="1" aria-current="true" aria-label="Slide 2"></button>
                                            </div>
                                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselNews" data-bs-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button class="carousel-control-next" type="button" data-bs-target="#carouselNews" data-bs-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <span v-html="$t(post.description)"></span>
                                    </div>
                                    <a class="buttonAction mt-5" style="width: fit-content; margin-top: 5px !important; display: block;" v-if="post.id == 7" href="https://www.genelec.com/-/reference/dubbing-brothers-chooses-genelec-for-latest-paris-expansion" target="_blank">Read the article</a>
                                    <a class="buttonAction mt-5" style="width: fit-content; margin-top: 5px !important; display: block;" v-if="post.id == 8" href="https://www.liquidsonics.com/2024/11/24/language-is-no-barrier-the-dubbing-brothers-interview" target="_blank">Read the article</a>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </main>
        <div class="background"></div>
        <img class="wave-background" src="../assets/img/background_lines_wave.svg">

        <div class="row justify-content-center">
            <div class="col-11">
                <div class="newsAction">
                    <div class="buttonAction float-left" :class="{disabled: !prevPostId}" @click="prevPost()"><i class="fa fa-caret-left me-2"></i> {{ $t('yy4iy1af') }}</div>
                    <div class="buttonAction" @click="navigate('/blog')"><i class="fa fa-th-large me-2"></i> {{ $t('ryt8jm59') }}</div>
                    <div class="buttonAction float-right" :class="{disabled: !nextPostId}" @click="nextPost()"> {{ $t('81dp1fiq') }} <i class="fa fa-caret-right me-2"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import configStatic from '@/boot/configStatic';
import session from '@/boot/session';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';


export default {
    name: "BlogPostPage",
    props: {
        galleryID: String,
    },
    data() {
        return {
            prevPostId: null,
            nextPostId: null,
            langSelected: null,
            post: {},
            configStatic: configStatic,
            imagesnews: [
                { src: require("@/assets/img/front/italy1.jpg"), thumbnail: require("@/assets/img/front/italy1.jpg") },
                { src: require("@/assets/img/front/italy2.jpg"), thumbnail: require("@/assets/img/front/italy2.jpg") }
            ],
        }
    },
    mounted() {
        if (!this.lightbox) {
            this.lightbox = new PhotoSwipeLightbox({
                gallery: '#new-image-swipe',
                children: 'a',
                pswpModule: () => import('photoswipe'),
                initialZoomLevel: 'fit'
            });
            this.lightbox.init();
        }
        
        this.getPost(this.$route.params.id);
    },
    unmounted(){
        if (this.lightbox) {
            this.lightbox.destroy();
            this.lightbox = null;
        }
    },
    beforeMount () {
        this.langSelected = session.getLanguage();
    },
    methods: {
        getPost(id) {
            // continutul
            let response = null;
            for (let i in this.configStatic.BLOG_POSTS)
            {
                if (this.configStatic.BLOG_POSTS[i].id === parseInt(id))
                {
                    response = this.configStatic.BLOG_POSTS[i];
                    break;
                }
            }
            // simulate call
            this.prevPostId = response.prev_post_id;
            this.nextPostId = response.next_post_id;
            this.post = response;

            if(!response.id) {
                this.navigate("/blog");
            }
        },
        prevPost() {
            if (this.prevPostId) {
                this.navigate("/blog/post/"+ this.prevPostId)
                this.$router.go()
            }
        },
        nextPost() {
            if (this.nextPostId) {
                this.navigate("/blog/post/"+ this.nextPostId)
            }
        },
        async navigate(page){
            await this.$router.push(page);
            // this.$router.go()
            window.location.reload()
        }
    }
}
</script>